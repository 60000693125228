.login-page-main-div {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 700px;
    overflow-x: hidden;
    gap: 50px;
    padding-bottom: var(--footer-height);
}

.login-page-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    z-index: 20;
    padding: 40px 0;
    opacity: 0;
    animation: login-form 0.7s ease-in-out forwards;
    animation-delay: 0.5s;
}

.login-page-logo-div {
    position: relative;
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
}

.login-page-logo {
    width: 50%;
    min-width: 600px;
    max-width: 900px;
    height: 100%;
    animation: login-logo 0.7s ease-in-out;
}

.login-textfield {
    width: 100%;
}

.login-textfield-pass {
    width: 100%;
    margin-bottom: 25px;
}

.login-forgot-pass-link {
    color: var(--text);
    z-index: 30;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.login-forgot-pass-link:hover,
.login-forgot-pass-link:focus {
    text-decoration: underline;
    outline: none;
}



@keyframes login-logo {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes login-form {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media screen and (max-width:960px) {
    .login-page-main-div {
        padding: 20px;
        justify-content: space-evenly;
    }

    .login-page-logo-div {
        height: 100px;
    }

    .login-page-logo {
        min-width: unset;
        width: 100%;
    }

    .login-page-form {
        width: 100%;
        max-width: 400px;
    }
}