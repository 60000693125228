.autocomplete-input {
    background-color: transparent;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    text-decoration: none;
    width: 150px;
    height: 35px;
    border: 0 solid;
    outline: 1px solid;
    outline-color: white;
    outline-offset: 0px;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 10px;
}

.autocomplete-input::placeholder {
    color: white;
    text-align: center;
}

.autocomplete-input:hover::placeholder,
.autocomplete-input:focus-visible::placeholder {
    color: grey;
}


.autocomplete-input:hover,
.autocomplete-input:focus-visible {
    outline-color: transparent;
    outline-offset: 15px;
    background-color: white;
    color: black;
}

@media screen and (max-width:960px) {
    .autocomplete-input {
        font-size: 16px;
        width: 125px;
        height: 30px;
    }
}