.top-bar-main-div {
    width: 100vw;
    right: 0;
    height: var(--top-bar-height);
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    z-index: 200;
}


.top-panel-user-display {
    min-height: 40px;
    max-width: 40px;
    width: fit-content;
    justify-content: space-between;
    color: var(--text);
    color: white;
    display: flex;
    align-items: center;
    padding-right: 15px;
    border-radius: 25px;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    animation: user_display 2s ease-in-out forwards;
    animation-delay: 1s;
}

.top-panel-user-display>p {
    opacity: 0;
    height: 0;
    animation: user_display_p 0.5s 2s ease-in-out forwards;

}

.top-panel-user-display:hover {
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.3);
}


.top-bar-logo-div {
    height: 40px;
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 25px;
}

.top-bar-logo {
    display: block;
    width: auto;
    height: 100%;
}

.top-bar-logo-mobile {
    display: none;
}

.top-bar-theme-toggle {
    margin: 0 40px;
}

@keyframes user_display_p {
    0% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 21px;
    }
}

@keyframes user_display {
    0% {
        max-width: 40px;
    }

    100% {
        max-width: 400px;
    }
}

@media screen and (max-width:960px) {
    .top-bar-main-div {
        left: 0;
        justify-content: space-between;
        z-index: 0;
    }

    .top-bar-logo {
        display: none;
    }

    .top-bar-logo-mobile {
        display: block;
        width: auto;
        height: 100%;
        filter: invert(1);
    }

    .top-panel-user-display > p {
        display: none;
    }

    .top-panel-user-display {
        padding-right: 0;
    }

    .top-bar-logo-div {
        margin: 0;
        height: 30px;
    }

}