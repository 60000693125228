* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;

    -ms-overflow-style: none;
    scrollbar-width: none;

    font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}

::-webkit-scrollbar {
	width: 0px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgb(180, 180, 180);
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover{
    background-color: #395ebd;
}

#root {
    background: url('./Assets/Images/background.jpg');
    background-size: cover;
}

:root {
    --top-bar-height: 100px;
    --footer-height: 35px;

    --primary: #448aff;
    --secondary: #395ebd;
}