.projects-view-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.project-not-found-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found {
    font-size: 32px;
    color: white;
    text-transform: uppercase; 
    letter-spacing: 2px;
    margin-top: 100px;
}