.textfield-main-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.textfield-input-input.helper-text {
    margin-bottom: 0;
}

.helper-text-p {
    color: white;
    min-height: 20px;
    font-size: 12px;
    padding-left: 5px;
    margin-top: 5px;
}

.textfield-input-input.error~.helper-text-p {
    color: #f86262;
    text-shadow: 1px 1px 1px black;
}

.helper-text-action {
    min-height: 20px;
    font-size: 14px;
    padding-top: 2px;
    cursor: pointer;
    color: white;
    font-style: italic;
}

.show-pass-button {
    position: absolute;
    right: 10px;
    top: 24px;
    cursor: pointer;
}

.helper-text-action:hover {
    text-decoration: underline;
    text-shadow: 1px 1px 1px black;
}

.textfield-label {
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 16px;
    color: white
}

.textfield-input-input {
    width: 100%;
    height: 35px;
    letter-spacing: 1px;
    font-weight: 300;
    outline: none;
    transition: all 0.2s ease-in-out;
    padding-left: 10px;
    color: black;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.7);
}

.textfield-endIcon {
    position: absolute;
    top: 2px;
    right: 5px;
}

.textfield-endIcon>svg:hover {
    cursor: pointer;
    scale: 1.1
}

.textfield-input-input:disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
}

.textfield-input-input.error {
    border: 1px solid red;
    background-color: white;
    color: red;
    box-shadow: 4px 4px 2px 0 rgba(0, 0, 0, 0.5);
}

.textfield-input-input:hover,
.textfield-input-input:focus-visible {
    box-shadow: 4px 4px 2px 0 rgba(0, 0, 0, 0.5);
    transform: translateY(-1px);
    background-color: rgba(255, 255, 255, 1);
}