.contacts-dialog-main-div {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    padding: 10px 20px 20px;
}

.contact-dialog-right-div {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: white;
}

.contact-dialog-form-div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.contacts-dialog-joined-fields {
    display: flex;
    gap: 10px;
    width: 100%;
}