.text-main-div {
    color: white;
    padding: 20px;
    overflow-y: auto;
    max-height: 80vh;
}

.text-main-div>h1 {
    margin-top: 25px;
    font-weight: 500;
    font-size: 20px;
    white-space: pre-wrap;
}

.text-main-div>ul {
    margin-left: 50px
}

.nested-li>li {
    margin-left: 20px;
    counter-increment: list;
}

.nested-li>li::marker {
    content: counter(list, upper-roman) " )\a0";
}

@media screen and (max-width: 900px) {
    .text-main-div {
        height: 95dvh;
    }
}