.projects-container-main-div {
    flex: 1 1;
    height: 100%;
    display: flex;
    gap: 20px;
}

.projects-container-div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    overflow-y: hidden;
    flex: 1 1;
    transition: all 0.3s ease-in-out;
}

.projects-container-div.expanded {
    overflow-y: visible;
}


.projects-container-action {
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px;
    position: sticky;
    top: 0;
}

.expand-arrow {
    width: 60%;
    height: max-content;
    rotate: 180deg;
    cursor: pointer;
    transition: rotate 0.2s ease-in-out;
}

.expand-arrow.expanded {
    rotate: 360deg;
}

.expand-arrow.retracted.hide {
    opacity: 0;
    pointer-events: none;
}

.expand-arrow.expanded.hide {
    opacity: 1;
    pointer-events: all;
}

.expand-arrow:hover {
    scale: 1.2;
}


@media screen and (max-width:960px) {

    .projects-container-main-div {
        display: block;
        padding: 0 10px;
        margin-top: -25px;
    }

    .projects-container-action {
        width: 100%;
        padding-top: 10px;
        max-width: 450px;
    }

    .expand-arrow {
        height: 25px;
    }

    .expand-arrow:hover {
        scale: 1;
    }
}