.table-info-main-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
}

.table-info-top-div {
    width: 100%;
    height: 100px;   
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 0 10px;
}

.table-info-top-div > img {
    height: 90%;
    width: auto;
}

.table-info-div {
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.table-info-div span {
    font-weight: 600;
}

.table-actions-div {
    height: 100%;
    padding: 10px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.table-scrollable-wrapper {
    max-height: 600px;
    height: fit-content;
    overflow-y: auto;
    padding-right: 5px;
}

.table-info-content {
    border: 1px solid white;
    width: 100%;
    /* max-height: 600px; */
    height: fit-content;
    overflow-y: hidden;
    border-radius: 10px;
    color: white;
}


.table-info-rows {
    width: 100%;
    height: fit-content;
    min-height: 25px;
    margin: 5px 0;
    padding: 0 5px;
    display: flex;
    gap: 10px;
}

.table-info-rows > p {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    border-radius: 10px;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.5); 
    border: 1px solid var(--primary);
}

.table-info-inner-rows {
    display: flex;
    flex-direction: column;
    width: 95%;
    gap: 5px;
}

.table-info-projects {
    border: 1px solid var(--primary);
    height: 35px;
    width: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.5);
}

.table-info-projects:hover {
    background-color: var(--secondary);
}

.table-info-projects > p {
    flex: 1 1;
    text-align: center;
    line-height: 35px;
}

.table-info-header {
    display: flex;
    width: 100%;
    padding: 5px;
    background-color: white;
    color: black;
    gap: 10px;
}

.table-info-inner-header {
    width: 95%;
    display: flex;
}

.table-info-inner-header > p {
    flex: 1 1;
    text-align: center;
}

.table-info-header > p {
    width: 5%;
    text-align: center;
}

.table-scrollable-wrapper::-webkit-scrollbar {
	width: 10px;
}

.table-project-div {
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.table-project-div > svg {
    cursor: pointer;
}

@media screen and (max-width:960px) {
    .table-info-top-div {
        flex-direction: column;
        height: fit-content;
    }

    .table-info-top-div > img {
        width: 70%;
    }

    .table-info-div {
        text-align: center;
    }

    .table-actions-div {
        margin: 0;
        gap: 10px;
        height: unset;
        padding-top: 0;
    }

    .table-info-content {
        min-width: 700px;
    }
}