.tables-dialog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 10px;
    max-height: 600px;
    overflow-y: auto;
}

.table-container {
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    gap: 10px;
    color: white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    overflow-y: auto;
    position: relative;
}

.table-container:hover {
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.2);
}

.table-container>img {
    margin-top: 10px;
    width: 60%;
    height: auto;
}

.table-content-title {
    font-weight: 700;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.tables-dialog-container::-webkit-scrollbar {
    width: 10px;
}

.year-chip {
    position: absolute
}

.projects-table-loading {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: white;
}
