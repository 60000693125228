.main-share-dialog-div {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 10px 10px 20px;
    flex-wrap: wrap;
    gap: 10px;
}

.main-share-dialog-div > *:hover {
    filter: opacity(0.8);
}

.dialog-share-copy-button {
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary);
    border-radius: 50%;
    cursor: pointer;
}

.dialog-share-copy-button > svg {
    scale: 0.9;
}

@media screen and (max-width:960px) {
    .main-share-dialog-div {
        gap: 10px 20px;
    }
}