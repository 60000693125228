.list-box-div {
    min-width: 150px;
    max-width: 300px;
}

.list-box-div > ul {
    padding: 0;
}

.list-box-list-item {
    display: flex;
    align-items: center;
    padding: 2.5px 20px;
    cursor: pointer;
    min-height: 40px;
    position: relative;
}

.list-box-list-item-icon.show {
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
}

.list-box-list-item-icon.hide {
    display: none;    
}

.list-box-list-item.selected {
    background-color: rgba(255, 255, 255, 0.4);
}

.list-box-list-item > p {
    color: white;
    text-align: center;
    width: 100%;
    font-size: 18px;
    text-shadow: 1px 1px 2px black;
}

.list-box-list-item.Mui-focused{
    background-color: rgba(255, 255, 255, 0.4);
}

.list-box-list-item:hover {
    background-color: rgba(255, 255, 255, 0.4);
}