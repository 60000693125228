.loading-page-main-div {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    gap: 20px;
    background-color: rgba(0,0,0,0.3);
    z-index: 500;
    color: white;
}