.filters-main-div {
    width: 100%;
    height: fit-content;
    padding: 25px 0 25px 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.filters-inputs-div {
    display: flex;
    gap: 20px;
}

.filters-chips-div {
    display: flex;
    gap: 50px;
}

.filters-chips-group {
    border-left: 2px solid white;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: all 0.3s ease-in-out;
}

.filters-chips-group.show {
    display: flex;
}

.filters-chips-group.hide {
    display: none;
}

.filters-chips-group>p {
    color: white;
}

.filter-chips {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.mobile-filter-button {
    display: none;
}

@media screen and (max-width:960px) {
    .filters-main-div {
        padding: 25px 10px;
        position: absolute;
        z-index: 20;
        top: -100%;
        background-color: #11134dc0;
        backdrop-filter: blur(1px);
        transition: all 0.6s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .filters-main-div.show {
        top: -170px;
        box-shadow: 1px 4px 4px 1px rgba(0,0,0,0.5);
    }

    .filters-inputs-div {
        justify-content: center;
        flex-wrap: wrap;
    }

    .filters-chips-div {
        flex-direction: column;
        gap: 10px;
    }

    .mobile-filter-button {
        display: block;
        align-self: center;
    }
}