.dashboard-main-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dashboard-tabs-div {
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 25px 0 25px 50px;
}

.dashboard-tabs-div>p {
    color: white;
}

.dashboard-tab {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    line-height: 36px;
}

.dashboard-tab.active {
    font-weight: 600;
}

.dashboard-tab.inactive {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #ffffffad;
    border-color: #ffffffad;
}

.dashboard-tab.inactive:hover {
    color: white;
    border-color: white;
}

.tabs-main-div {
    position: relative;
    width: 100%;
    flex: 1 1;
    background: linear-gradient(180deg, transparent, rgba(15, 31, 71, 1));
}

.dashboard-tabs-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
}

.dashboard-tabs-wrapper.show {
    width: 100%;
    transition: all 0.7s 0.4s ease-in-out;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
}


.dashboard-tabs-wrapper.hide {
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
    transition: all 0.7s ease-in-out;
}

@media screen and (max-width:960px) {

    .dashboard-tabs-div {
        padding: 10px;
        gap: 0;
        justify-content: center;
        text-align: center;
    }

    .dashboard-tabs-div :nth-child(2) {
        margin: 0 10px;
        line-height: 25px;
    }

    .dashboard-tabs-div>p {
        font-size: 16px;
        /* max-width: 50%; */
        line-height: 16px;
        height: 50px;
    }
}