.shared-projects-main-div {
    height: 100%;
    width: 100%;
}

.shared-projects-content::-webkit-scrollbar {
	width: 10px;
	background: rgb(104, 104, 104);
}


.shared-projects-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    padding: 0 25px 80px 50px;
    overflow-y: auto;
    scrollbar-width: auto;
}


@media screen and (max-width:960px) {

    .shared-projects-main-div {
        display: flex;
        flex-direction: column;
    }

    .shared-projects-content::-webkit-scrollbar {
        width: 0;
    }

    .shared-projects-content {
        padding: 20px;
        margin-bottom: 25px;
    }
}