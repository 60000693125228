.language-changer-div {
    display: flex;
}

.language-menu-item {
    display: flex;
    width: 100%;
    opacity: 0.3;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.language-menu-item.selected {
    opacity: 1;
    cursor: default;
}

.language-menu-item.unselected:hover {
    opacity: 1;
}

.language-menu-item > img {
    height: 25px;
    margin-right: 10px;
}

.language-menu-item > p {
    font-size: 20px;
}

.language-menu-item.selected > p {
    font-weight: 500;
}

