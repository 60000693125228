.projects-list-main-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.projects-content::-webkit-scrollbar {
    width: 10px;
}

.projects-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow-x: hidden;
    scrollbar-width: auto;
    margin-top: 20px;
    padding: 5px 25px 80px 50px;
}

.projects-content-company-group {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 20px;
    position: relative;
    animation: project-company-container 0.3s ease-in-out;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
    padding: 20px 10px;
    border-radius: 20px;
    background-color: rgb(0 0 0 / 15%);
}

.projects-content-company-info {
    width: 15%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 200px;
    gap: 10px;
    top: 0;
    position: sticky;
}

.projects-content-image-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.projects-content-image-div>img {
    height: 5rem;
}

.projects-content-image-div>p {
    color: white;
    letter-spacing: 1px;
    font-size: 20px;
    text-align: center;
}

.show-filters-button {
    display: none;
}

@keyframes project-company-container {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.projects-content-actions-div {
    margin-top: 10px;
    display: block;
}


@media screen and (max-width:960px) {

    .projects-content::-webkit-scrollbar {
        width: 0px;
    }

    .show-filters-button {
        display: block;
        width: 75%;
        max-width: 400px;
        align-self: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
    }

    .show-filters-button.show {
        opacity: 1;
        pointer-events: all;
    }

    .projects-content {
        padding: 10px 0;
        margin-bottom: 50px;
    }

    .projects-content-company-info {
        width: 100%;
        z-index: 20;
        min-width: unset;
        flex-direction: row;
        background: rgb(255 255 255 / 75%);
        padding: 5px 0;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 35px;
    }

    .projects-content-image-div>img {
        height: 35px;
    }

    .projects-content-company-group {
        flex-direction: column;
    }

    .projects-content-actions-div {
        margin-top: 0;
    }

    .projects-content-image-div>p {
        display: none;
    }

}