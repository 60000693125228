.project-card-main-div {
    background-color: grey;
    height: 200px;
    width: 300px;
    max-width: 450px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    animation: projectCard 0.5s ease-in-out;
}

.project-card-main-div:hover {
    /* transform: translateY(-2px); */
    box-shadow: 4px 4px 4px 1px rgb(0 0 0 / 50%);
}

.project-card-main-div:hover>img {
    scale: 1.05;
}


.project-card-main-div:hover>.project-card-actions-div {
    background-color: white;
}

.project-card-main-div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: scale 0.3s ease-in-out;
}

.project-card-actions-div {
    bottom: 0;
    width: 100%;
    position: absolute;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
    transition: all 0.3s ease-in-out;
    backdrop-filter: blur(2px);
}

.project-card-name-div {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 5px 0;
    font-weight: 500;
    z-index: 10;
    backdrop-filter: blur(2px);
}

.project-icon {
    height: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.project-icon:hover {
    scale: 1.1;
}

@keyframes projectCard {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@media screen and (max-width:960px) {

    .project-card-actions-div {
        justify-content: space-evenly;
    }

    .project-card-main-div {
        width: 100%;
    }

}