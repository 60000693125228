.qrcode-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.qrcode-link {
    text-align: center;
    color: white;
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    
}

.qrcode-dialog-actions {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}