.loading-button-main-div {
	appearance: none;
	color: white;
	cursor: pointer;
	line-height: 1;
	overflow: hidden;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	background-color: rgba(255,255,255,0.4);
	transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	border: 0 solid;
    outline: 1px solid;
    outline-color: white;
    outline-offset: 0px;
}

.loading-button-main-div.small {
	height: 30px;
	min-width: 0px;
	font-size: 16px;
	padding: 2px 20px;
}

.loading-button-main-div.medium {
	height: 40px;
	min-width: 140px;
	font-size: 18px;
	padding: 2px 20px;
}

.loading-button-main-div.large {
	height: 50px;
	min-width: 160px;
	font-size: 20px;
	padding: 2px 30px;
}


.button-loading-div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
	justify-content: center;
}

.loading-button-main-div:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.loading-button-main-div.loading {
	pointer-events: none;
	cursor: not-allowed;
}

.loading-button-main-div:not([disabled]):focus-visible,
.loading-button-main-div:not([disabled]):hover,
.loading-button-main-div:not([disabled]):active {
	outline-color: transparent;
    outline-offset: 15px;
    background-color: white;
    color: black;
}