.footer-main-div {
    background-color: rgba(15, 31, 71, 0.8);
    position: fixed;
    bottom: 0;
    height: var(--footer-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 25px;
    animation: footer 0.4s ease-in-out;
}

.footer-links {
    display: flex;
    gap: 30px;
    color: white;
    font-size: 14px;
}

.footer-links>p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.footer-links-mobile {
    display: none;
}

@keyframes footer {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width:960px) {

    .footer-main-div {
        padding: 0 10px 0 20px;
        z-index: 30;
    }

    .footer-links {
        display: none;
    }

    .footer-links-mobile {
        display: flex;
        gap: 30px;
        color: white;
        font-size: 14px;
        position: relative;
    }

    .footer-mobile-panel {
        position: absolute;
        bottom: 26px;
        opacity: 0;
        transform: translateX(-100%);
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: rgba(15, 31, 71, 0.8);
        left: -20px;
        padding: 10px 20px;
        border-top-right-radius: 10px;
        transition: all 0.3s ease-in-out;
    }

    .footer-mobile-panel.show {
        transform: translateX(0);
        opacity: 1;
    }


}