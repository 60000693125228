.reset-password-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.reset-password-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.reset-password-buttons {
    display: flex;
    justify-content: space-around;
}

.reset-password-top-text {
    margin-bottom: 25px;
    text-align: center;
    color: white
}

.reset-pass-user-name {
    font-size: 22px;
    color: white;
}

@media screen and (max-width:960px) {
    .reset-password-main-div {
        padding: 20px;
    }

    .reset-password-form {
        max-width: 400px;
        width: 100%;
    }
}