.search-input-main-div {
    display: flex;
    align-items: center;
    position: relative;
}

.search-input-icon {
    position: absolute;
    left: 10px;
    top: 5px;
}

.search-input-close {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}

.search-input-input {
    width: 100%;
    min-width: 300px;
    height: 35px;
    background-color: transparent;
    color: white;
    text-indent: 50px;
    border: 0px solid;
    outline: 1px solid;
    outline-color: white;
    outline-offset: 0px;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    background-color: rgba(255, 255, 255, 0.5);
}

.search-input-main-div:hover .search-input-input,
.search-input-input:focus {
    min-width: 350px;
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    border: 1px solid;
}

/* .search-input-main-div:hover .search-input-input,
.search-input-input:focus {

} */

.search-input-input::placeholder {
    color: white;
    font-size: 16px;
    font-family: 400;
}

@media screen and (max-width:960px) {
    .search-input-input {
        height: 30px;
        min-width: 250px;
    }

    .search-input-main-div:hover .search-input-input,
    .search-input-input:focus {
        min-width: 250px;
    }
}